import {
  Box,
  Heading,
  HeadingProps,
  IconButton,
  IconButtonProps,
} from '@chakra-ui/react';
import React from 'react';

import { Logo } from './icons';

type Props = HeadingProps & Partial<IconButtonProps>;

export default ({ onClick, children, ...rest }: Props): JSX.Element => {
  return (
    <Box
      role="button"
      spacing={0}
      textAlign="center"
      color="gray.900"
      onClick={onClick}
    >
      <IconButton
        color="currentColor"
        aria-label="back to home"
        variant="ghost"
        icon={<Logo boxSize="1.5em" />}
      />
      <Heading
        textAlign="center"
        color="currentColor"
        fontWeight={900}
        lineHeight={1}
        size="lg"
        {...rest}
      >
        {children}
      </Heading>
    </Box>
  );
};
