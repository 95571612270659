import {
  Box,
  Flex,
  Image as ImageChakra,
  ImageProps,
  Spinner,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';

/**
 * A img element with some animations attached to it
 */
const MotionImageChakra = motion.custom(ImageChakra);

const AnimatedImage = React.forwardRef((props, ref) => (
  <MotionImageChakra
    ref={ref}
    as="img"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    width="100%"
    height="100%"
    objectFit="cover"
    /* TODO may want decoding. Have a look into it
     * also, see use of img.decode() in the resource */
    // decoding="sync"
    {...props}
  />
));

/*
 * Container to be wrapped around all images
 */
const ImageContainer = React.forwardRef((props, ref) => (
  <Box ref={ref} width="100%" height="100%" {...props} />
));

/*
 * For blurring out the low res version of the image
 */
const blurryCss = {
  filter: 'blur(2px)',
};

type Props = Partial<ImageProps>;

/**
 * Chakra Image component does have a fallbackSrc prop. So if this is
 * too overcomplicated, we could just use that.
 *
 * @param {*} param0
 */
const ImageResponsive = React.forwardRef<HTMLElement>(
  (
    { src, alt, onLoad = () => {}, onError = () => {}, ...rest }: Props,
    ref,
  ): JSX.Element => {
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const shouldShowImage = loaded && !error;

    const onErrorCallback = useCallback(
      (...args) => {
        setError(true);
        onError(...args);
      },
      [onError],
    );

    const onLoadCallback = useCallback(
      (...args) => {
        setLoaded(true);
        onLoad(...args);
      },
      [onLoad],
    );

    return (
      <AnimatePresence>
        <ImageContainer ref={ref}>
          <AnimatedImage
            src={src}
            alt={alt}
            position="absolute"
            display={!shouldShowImage && 'none'}
            onError={onErrorCallback}
            onLoad={onLoadCallback}
            {...rest}
          />
          {!loaded && (
            <Flex
              sx={!error ? blurryCss : undefined}
              position="absolute"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              backgroundColor="gray.300"
              zIndex="hide"
              {...rest}
            >
              {!error ? <Spinner size="sm" color="gray.700" /> : null}
            </Flex>
          )}
        </ImageContainer>
      </AnimatePresence>
    );
  },
);

export default ImageResponsive;
