import { StarIcon } from '@chakra-ui/icons';
import {
  AspectRatio,
  chakra,
  Heading,
  HStack,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import getDistance from 'geolib/es/getDistance';
import isPointWithinRadius from 'geolib/es/isPointWithinRadius';
import { groupBy, mapObjIndexed } from 'ramda';
import React, { useMemo } from 'react';

import { MotionBox } from '../motion';
import { Item, PostcodeData } from '../types';
import ImageResponsive from './ImageResponsive';

interface Props extends Item {
  open: () => void;
}

const ListItem = motion.custom(chakra.li);

function Card({
  id,
  title,
  description,
  collection,
  delivery,
  deliveryType,
  open,
}: Props) {
  const tags: string[] = [];

  const collectionOnly = collection === true && delivery === false;
  const nationWide = delivery === true && deliveryType === 'Nationwide';

  // eslint-disable-next-line no-nested-ternary
  const imageTag = collectionOnly
    ? 'Collection Only'
    : nationWide
    ? 'Delivers NationWide'
    : undefined;

  return (
    <ListItem
      position="relative"
      cursor="pointer"
      whileHover={{
        scale: 1.02,
      }}
      transition={{ duration: 0.2 }}
    >
      <div>
        <Stack
          as={motion.div}
          tabIndex="0"
          key={id}
          onClick={() => {}}
          cursor="pointer"
          spacing={0}
          rounded="lg"
          position="relative"
          layoutId={`card-container-${id}`}
        >
          <MotionBox
            position="relative"
            layoutId={`card-image-container-${id}`}
          >
            <AspectRatio w="100%" ratio={16 / 10}>
              <ImageResponsive
                alt={title}
                src={`./images/${id}.jpg`}
                rounded="lg"
              />
            </AspectRatio>
            <Heading
              opacity={imageTag ? 1 : 0}
              as="h3"
              position="absolute"
              top={0}
              rounded="md"
              px={2}
              py={1}
              mx={3}
              my={3}
              bg="white"
              size="sm"
              fontWeight={800}
              textTransform="uppercase"
            >
              {imageTag}
            </Heading>
          </MotionBox>
          <Stack
            as={motion.div}
            layoutId={`title-container-${id}`}
            paddingTop={2}
            spacing={4}
          >
            <Stack spacing={0}>
              <Heading
                as="h1"
                size="md"
                m={0}
                color="gray.800"
                fontWeight={400}
              >
                {title}
              </Heading>
              <Heading
                as="p"
                size="sm"
                m={0}
                fontWeight={400}
                color="gray.700"
                isTruncated
              >
                {description}
              </Heading>
            </Stack>
            <HStack>
              {tags.map((tag) => (
                <Tag size="md" key={tag} variant="outline" colorScheme="gray">
                  <TagLeftIcon boxSize="12px" as={StarIcon} />
                  <TagLabel>{tag}</TagLabel>
                </Tag>
              ))}
            </HStack>
          </Stack>
        </Stack>
      </div>
      <chakra.a onClick={open} className="card-open-link" />
    </ListItem>
  );
}

export function CardList({
  items,
  setSelected,
  postcodeData,
}: {
  items: Item[];
  setSelected: (card: Item) => void;
  postcodeData?: PostcodeData;
}): JSX.Element {
  const filteredItems = !postcodeData
    ? items.slice(0, 20) // preload the first few images in the background just in case
    : items.filter((card) => {
        switch (card.deliveryType) {
          case 'Nationwide': {
            return true;
          }
          case 'Postcode': {
            if ((card.postcodes, postcodeData?.outcode)) {
              if (
                card.postcodes?.includes(postcodeData?.outcode) ||
                card.postcodes?.includes(postcodeData?.postcode)
              ) {
                return true;
              }
              return false;
            }
            console.log(
              `${card.id} has invalid data for deliveryType Postcode`,
            );
            return false;
          }
          case 'Radius': {
            if (
              postcodeData?.longitude &&
              postcodeData?.latitude &&
              card.latitude &&
              card.longitude &&
              card.radius
            ) {
              const cardGeo = {
                longitude: card.longitude,
                latitude: card.latitude,
              };
              const cardRadius = card.radius * 1609.344; // metres
              return isPointWithinRadius(postcodeData, cardGeo, cardRadius);
            }
            console.log(`${card.id} has invalid data for deliveryType Radius`);
            return false;
          }
          case 'Phone bar and ask': {
            console.log('TODO, phone', card.id);
            return postcodeData.region === card.region;
          }
          case 'Region': {
            if (postcodeData && card.region) {
              return postcodeData.region === card.region;
            }
            console.log(`${card.id} has was missing a region?`);
            return false;
          }
          default: {
            if (card.collection) {
              if (card.region && postcodeData?.region) {
                return card.region === postcodeData?.region;
              }
            }
            console.log(
              `${card.id} has no delivery type and is not collection or has no region ${card.region}`,
            );
            return false;
          }
        }
      });

  const sortedItems = useMemo(() => {
    console.log(
      'stats',
      mapObjIndexed(
        (xs: Item[]) => xs.length,
        groupBy((d) => d?.deliveryType || 'unknown', filteredItems),
      ),
    );

    return !postcodeData
      ? filteredItems
      : filteredItems
          .map((card) => {
            let distanceAway = Infinity;
            if (card.featured) {
              distanceAway = 0;
            } else if (card.longitude && card.latitude) {
              distanceAway = getDistance(
                postcodeData,
                {
                  longitude: card.longitude,
                  latitude: card.latitude,
                },
                100,
              );
            }
            return {
              ...card,
              distanceAway,
            };
          })
          .sort((a, b) => a.distanceAway - b.distanceAway);
  }, [filteredItems, postcodeData]);

  return (
    <Stack
      spacing={8}
      visibility={postcodeData === undefined ? 'hidden' : undefined}
    >
      <Text fontSize="sm" textAlign="center" color="gray.500">
        {sortedItems?.length} found
      </Text>
      <Stack as="ul" spacing={16}>
        {sortedItems.map((card) => (
          <Card
            key={card.id}
            {...card}
            open={() => {
              setSelected(card);
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}
