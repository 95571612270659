import { SearchIcon } from '@chakra-ui/icons';
import {
  FormControl,
  HStack,
  Input,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { useCombobox } from 'downshift';
import { motion } from 'framer-motion';
import React, { SyntheticEvent, useState } from 'react';
import useSWR from 'swr';

const MotionFormControl = motion.custom(FormControl);

type Props = {
  onClick: (thing: any) => void;
  onSelect: (thing: any) => void;
};

const fetchSuggestions = async (endpoint: string): Promise<string[]> =>
  fetch(`https://api.postcodes.io/${endpoint}`, {
    // 'no-cors', // 'cors' by default
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw Error(response.status.toString() || 'no status');
    })
    .then((data) => {
      const { status, result } = data;
      if (status === 200) {
        return result;
      }
      throw Error(data);
    });

const SearchCombobox = ({
  onSelect,
  onChange,
  renderHints,
  inputValue,
  onClick = () => {},
  ...rest
}: Props) => {
  const [isTyping, setIsTyping] = useState(false);
  const { data, revalidate: refetch, isValidating: isQueryLoading } = useSWR(
    `postcodes/${inputValue}/autocomplete`,
    fetchSuggestions,
  );

  const {
    isOpen,
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
  } = useCombobox({
    // inputValue,
    items: data || [],
    itemToString: (postcode) => postcode?.toUpperCase() || 'error',
    onInputValueChange: ({
      inputValue: newInputValue,
      isOpen: isOpenOnChange,
    }) => {
      // Avoid set input value when is not open as that means the user already
      // picked an option so we don't want to refetch again.
      // Also, we keep original value entered by user in case they want to select
      // other option from last results.
      if (isOpenOnChange) {
        onChange(newInputValue ?? '');
      }
      setIsTyping(!!isOpenOnChange);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem);
      setIsTyping(false);
    },
    // stateReducer: (state, actionAndChanges) => {
    //   const { type, changes } = actionAndChanges;
    //   // returning an uppercased version of the item string.
    //   switch (type) {
    //     case useCombobox.stateChangeTypes.InputChange:
    //       console.log(useCombobox.stateChangeTypes.InputChange, type);
    //       return {
    //         // return normal changes.
    //         ...changes,
    //         // but taking the change from default reducer and uppercasing it.
    //         inputValue: changes?.inputValue?.toLocaleUpperCase(),
    //       };
    //     default:
    //       return changes;
    //   }
    // },
  });

  // const throttleRefetch = useMemo(() => {
  //   return debounce(() => {
  //     setIsTyping(false);
  //     refetch();
  //   }, 500);
  // }, [refetch]);

  // useEffect(() => {
  //   if (inputValue.trim()) {
  //     throttleRefetch();
  //   }
  // }, [inputValue, throttleRefetch]);

  const isLoading = isTyping || isQueryLoading;

  return (
    <MotionFormControl
      isRequired
      width="full"
      position="relative"
      {...getComboboxProps()}
      {...rest}
    >
      <HStack w="full" justifyContent="center" position="relative" h={20}>
        <Input
          {...rest}
          onClick={(e: SyntheticEvent<HTMLInputElement>) => {
            setIsTyping(true);
            e.target?.select?.();
            onClick();
          }}
          onBlur={() => {
            setIsTyping(false);
          }}
          onFocus={() => {
            setTimeout(() => {
              window.scrollTo(0, 0);
              document.body.scrollTop = 0;
            }, 100);
            setTimeout(() => {
              window.scrollTo(0, 0);
              document.body.scrollTop = 0;
            }, 250);
          }}
          background="gray.100"
          w="100%"
          h="100%"
          textAlign="center"
          fontWeight={700}
          rounded="xl"
          shadow="md"
          border="none"
          focusBorderColor="teal.500"
          {...getInputProps()}
          _hover={{
            background: 'gray.200',
            border: 'none',
          }}
          _placeholder={
            isTyping
              ? undefined
              : {
                  color: 'gray.800',
                  opacity: 1,
                }
          }
        />
        <SearchIcon
          zIndex={isTyping ? 2 : undefined}
          position="absolute"
          left={['10%', '20%', '25%']}
          color="teal.600"
        />
      </HStack>
      <List
        {...getMenuProps()}
        position="absolute"
        width="full"
        mt={1}
        shadow="md"
        rounded="xl"
        backgroundColor="gray.100"
        borderColor="gray.200"
        zIndex="dropdown"
        py={6}
        opacity={isOpen ? 1 : 0}
      >
        {isOpen ? (
          <>
            {renderHints?.()}
            {data?.map((suggestion, index) => (
              <ListItem
                px={6}
                py={2}
                key={suggestion}
                {...getItemProps({ item: suggestion, index })}
                backgroundColor={
                  highlightedIndex === index ? 'gray.200' : undefined
                }
                cursor="pointer"
              >
                <Text fontSize="sm" fontWeight={700} color="inherit">
                  {suggestion}
                </Text>
              </ListItem>
            ))}
          </>
        ) : null}
      </List>
    </MotionFormControl>
  );
};

export default SearchCombobox;
