import React, { Provider, useContext, useState } from 'react';

const defaultValue = {
  postcode: '',
  setPostcode: (_postcode: string): void => {},
};

const PostcodeContext = React.createContext(defaultValue);

export const PostcodeProvider = ({
  children,
}: {
  children: React.ReactChild;
}): JSX.Element => {
  const [postcode, setPostcode] = useState('');

  return (
    <PostcodeContext.Provider value={{ postcode, setPostcode }}>
      {children}
    </PostcodeContext.Provider>
  );
};

export const usePostcode = (): typeof defaultValue => {
  return useContext(PostcodeContext);
};
