import { Box } from '@chakra-ui/react';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import * as React from 'react';

import { Item, PostcodeData } from '../types';
import { CardList } from './CardList';
import { ItemComponent } from './Item';

export const CardView = ({
  postcodeData,
  items,
}: {
  items: Item[];
  postcodeData?: PostcodeData;
}): JSX.Element => {
  const [item, setSelected] = React.useState<Item>();

  const imageHasLoaded = true;

  // const item = items.find((d) => d.id === selectedId);

  return (
    <Box marginBottom={32}>
      <AnimateSharedLayout type="crossfade">
        <CardList
          setSelected={setSelected}
          postcodeData={postcodeData}
          items={items}
        />
        <AnimatePresence>
          {item !== undefined && imageHasLoaded && (
            <ItemComponent
              item={item}
              key="item"
              close={() => {
                setSelected(undefined);
              }}
            />
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </Box>
  );
};
