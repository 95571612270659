import { Box, Button, Center, CenterProps, HStack } from '@chakra-ui/react';
import { Link } from 'gatsby';
import React from 'react';

import InstaLink from './InstaLink';

const COPY = ['About us', '|', 'List your cocktails'];

export default (props: CenterProps): JSX.Element => (
  <Center pb={16} textAlign="center" {...props}>
    <HStack justifyContent="center">
      <Button as={Link} to="about" variant="link" color="gray.900" mt="-1">
        {COPY.map((text) => (
          <Box key={text} as="span" pl={2}>
            {text}
          </Box>
        ))}
      </Button>
      <InstaLink />
    </HStack>
  </Center>
);
