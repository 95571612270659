import { IconButton, IconButtonProps, Link, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { FaInstagram } from 'react-icons/fa';

const INSTA = '@bringyourbarhome';
const INSTA_URL = 'https://www.instagram.com/bringyourbarhome/';

export default (props: Partial<IconButtonProps>): JSX.Element => (
  <Tooltip hasArrow label={INSTA} bg="gray.900" color="white" rounded="md">
    <IconButton
      as={Link}
      isExternal
      href={INSTA_URL}
      aria-label={`Link to ${INSTA} instagram account`}
      size="sm"
      variant="ghost"
      icon={<FaInstagram />}
      {...props}
    />
  </Tooltip>
);
