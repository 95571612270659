import { createIcon } from '@chakra-ui/react';
import * as React from 'react';

// using `path`
export const Logo = createIcon({
  displayName: 'Logo',
  viewBox: '0 0 27 28',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      d="M25.4666 27.4969C26.0189 27.4969 26.4666 27.0492 26.4666 26.4969V12.3323C26.4666 12.0497 26.347 11.7802 26.1374 11.5907L13.6088 0.258373C13.2213 -0.0921603 12.6292 -0.0852161 12.25 0.274309L0.31198 11.5926C0.112806 11.7815 0 12.0439 0 12.3183V26.4969C0 27.0492 0.447715 27.4969 1 27.4969H12.6867L12.4557 22.3818L4.76178 12.3871H22.0823L14.3698 22.406L14.0238 27.4969H25.4666Z"
      fill="currentColor"
    />
  ),
});
