import '../assets/other-styles.css';

import { Box, Stack, Text } from '@chakra-ui/react';
import { navigate } from '@reach/router';
import { PageProps } from 'gatsby';
import { path } from 'ramda';
import React from 'react';
import useSWR from 'swr';

import items from '../assets/data.json';
import BrandingButton from '../components/BrandingButton';
import { CardView } from '../components/CardView';
import { Layout } from '../components/Layout';
import LinkToAboutPage from '../components/LinkToAboutPage';
import SearchCombobox from '../components/SearchComboBox';
import Section from '../components/Section';
import { MotionHeading } from '../motion';
import { PostcodeData } from '../types';
import { usePostcode } from '../utils/usePostcode';

const isSSR = typeof window === 'undefined';

export const COPY = {
  landing: {
    title: ['Bring', 'Your Bar', 'Home'].map((s) => s.toLocaleUpperCase()),
    tagline: 'Find cocktail delivery & takeaway near you',
    button: 'What’s your postcode?',
  },
  search: {
    hints: {
      beforeTyping:
        'Support your local during lockdown. Enjoy your favourite cocktails at home!',
      onceTyping: 'Enter your full postcode to show local deliveries',
      validPostcode: '',
    },
  },
};

export const VARIANTS = {
  landing: {
    title: {},
    tagline: {},
    image: {},
    button: {},
    container: {},
    hints: {
      display: 'none',
    },
  },
  search: {
    title: {
      display: 'none',
    },
    tagline: {
      display: 'none',
    },
    image: {
      opacity: 0,
    },
    button: {},
    container: {
      my: 0,
      py: 0,
      spacing: 6,
    },
    hints: {},
  },
};

const STATE_MACHINE = {
  landing: {
    logo: {
      nextView: 'landing',
    },
    button: {
      nextView: 'search',
    },
  },
  search: {
    logo: {
      nextView: 'landing',
    },
  },
};
type View = keyof typeof VARIANTS;

const PARAMS_WRITE = {
  search: ['s', ''],
  landing: undefined,
} as const;

const fetchPostcodeData = (
  endpoint: string,
): Promise<PostcodeData | undefined> =>
  fetch(`https://api.postcodes.io/${endpoint}`, {
    // 'no-cors', // 'cors' by default
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw Error(response.status.toString() || 'no status');
    })
    .then((data) => {
      const { status, result } = data;
      if (status === 200) {
        return result;
      }
      throw Error(data);
    })
    .then((result) => {
      return result;
    })
    .catch(() => {
      return undefined;
    });

export default function IndexPage({ location }: PageProps): JSX.Element {
  const { postcode, setPostcode } = usePostcode();
  const { data: postcodeData } = useSWR<PostcodeData | undefined>(
    `postcodes/${postcode}`,
    fetchPostcodeData,
  );

  const view =
    !isSSR &&
    window.location.search.includes(
      `${PARAMS_WRITE.search[0]}=${PARAMS_WRITE.search[1]}`,
    )
      ? 'search'
      : 'landing';

  const goToNextViewFor = (elementName: string) => {
    const nextView = path<View>([view, elementName, 'nextView'], STATE_MACHINE);

    if (nextView === 'search') {
      window?.plausible?.('GO_TO_SEARCH_VIEW', {
        callback: () => console.info('Sent GO_TO_SEARCH_VIEW event'),
      });
    }

    if (nextView) {
      const url = new URL(window.location.href);

      const oldSearch = PARAMS_WRITE?.[view]?.[0];
      if (oldSearch) {
        url.searchParams.delete(oldSearch);
      }

      const newSearch = PARAMS_WRITE?.[nextView];
      if (newSearch) {
        url.searchParams.set(newSearch[0], newSearch[1]);
      }

      navigate(url.toString());
    }
  };

  const updatePostcode = (value: string): void => {
    setPostcode(value.toLocaleUpperCase()); // update UI
  };

  return (
    <Layout>
      <Section p={0}>
        <Stack paddingTop={[10]} spacing={2} alignItems="center">
          <BrandingButton
            onClick={() => {
              goToNextViewFor('logo');
            }}
            {...VARIANTS[view].title}
          >
            {COPY.landing.title.map((s) => (
              <Box as="span" display="block" key={s}>
                {s}
              </Box>
            ))}
          </BrandingButton>
          <Stack
            spacing={[8, 16]}
            paddingX={6}
            w="100%"
            position="relative"
            {...VARIANTS[view].container}
          >
            {/* Image */}
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              zIndex={-1}
              position="absolute"
              {...VARIANTS[view].image}
            >
              {/* <ImageResponsive
                sx={{
                  filter: 'brightness(60%) contrast(80%)',
                }}
                shadow="sm"
                roundedTop="lg"
                src={heroImage}
                alt="hero image of generic kit"
              /> */}
            </Box>

            <MotionHeading
              initial={{ y: 40, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              color="black"
              fontWeight={900}
              size="3xl"
              {...VARIANTS[view].tagline}
            >
              {COPY.landing.tagline}
            </MotionHeading>

            <SearchCombobox
              // TODO: when we know the geo for a postcode we should force close the menu
              {...VARIANTS[view].button}
              onClick={() => {
                goToNextViewFor('button');
              }}
              placeholder={COPY.landing.button}
              onSelect={updatePostcode}
              onChange={updatePostcode}
              inputValue={postcode.toLocaleUpperCase()}
              renderHints={() => (
                <Text
                  fontSize="sm"
                  textAlign="center"
                  color="gray.500"
                  {...VARIANTS[view].hints}
                >
                  {COPY.search.hints.onceTyping}
                </Text>
              )}
            />
            {!postcode ? (
              <Text
                fontSize="sm"
                textAlign="center"
                color="gray.500"
                {...VARIANTS[view].hints}
              >
                {COPY.search.hints.beforeTyping}
              </Text>
            ) : null}
            <Stack paddingTop={6} spacing={12}>
              {/* Bottom if not landing or is mobile */}
              {view === 'search' && postcode ? (
                <>
                  <CardView postcodeData={postcodeData} items={items} />
                  <LinkToAboutPage
                    mb={24}
                    display={[
                      'none',
                      postcodeData === undefined ? 'none' : 'initial',
                    ]}
                    // position={['absolute']}
                  />
                </>
              ) : null}
            </Stack>
          </Stack>
          {view === 'landing' ? (
            <LinkToAboutPage
              justifySelf="flex-end"
              position={['static', null, 'absolute']}
              pr={[0, null, 4, 10]}
              right={0}
            />
          ) : null}
        </Stack>
      </Section>
    </Layout>
  );
}
