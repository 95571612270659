import {
  AspectRatio,
  Button,
  Center,
  chakra,
  CloseButton,
  Heading,
  HStack,
  Link,
  SlideFade,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { convertDistance } from 'geolib';
import React, { useCallback, useEffect, useRef } from 'react';
import { IconType } from 'react-icons';
import {
  FaBicycle,
  FaCaretDown,
  FaCaretUp,
  FaMapMarkerAlt,
  FaWalking,
} from 'react-icons/fa';

import { MotionBox } from '../motion';
import { Item } from '../types';
import ImageResponsive from './ImageResponsive';

interface ProcessedItem extends Item {
  distanceAway?: number;
}

export function ItemComponent({
  item,
  close,
}: {
  item: ProcessedItem;
  close: () => void;
}): JSX.Element {
  const {
    id,
    title,
    collection,
    delivery,
    description,
    deliveryNotes,
    deliveryType,
    provider,
    region,
    link,
    distanceAway,
  } = item || {};
  const tags: [IconType, string][] = [];

  if (delivery) {
    tags.push([FaBicycle, 'Delivery']);
  }
  if (collection) {
    tags.push([FaWalking, 'Collection']);
    if (distanceAway !== undefined && distanceAway !== Infinity) {
      let dist;
      if (distanceAway < 1000) {
        dist = `${parseFloat(distanceAway.toPrecision(1)).toLocaleString()} m`;
      } else {
        dist = `${parseFloat(
          convertDistance(distanceAway, 'mi').toPrecision(2),
        ).toLocaleString()} miles`;
      }
      tags.push([FaMapMarkerAlt, dist]);
    }
  }

  const collectionOnly = collection === true && delivery === false;
  const nationWide = delivery === true && deliveryType === 'Nationwide';

  // eslint-disable-next-line no-nested-ternary
  const imageTag = collectionOnly
    ? 'Collection Only'
    : nationWide
    ? 'Delivers NationWide'
    : undefined;

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        close();
      }
    },
    [close],
  );

  const buttonRef = useRef<HTMLElement>();
  useEffect(() => {
    if (id) {
      buttonRef.current?.focus();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    }

    return () => {
      document.body.style.overflow = 'initial';
      document.body.style.height = 'initial';
    };
  }, [id]);

  const deliveryColor = useColorModeValue('gray.500', 'gray.300');

  const { isOpen: deliveryNotesOpen, onToggle } = useDisclosure();
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        transition={{ duration: 0.2, delay: 0.15 }}
        style={{ pointerEvents: 'auto' }}
        className="overlay"
        // onClick={close} // is completely covered up
      />
      <VStack
        onKeyDown={onKeyDown}
        top="0"
        bottom={0}
        left={0}
        right={0}
        position="fixed"
        zIndex="10"
        overflow="hidden"
        alignItems="center"
        paddingTop={[null, null, 4]}
        onClick={() => {
          close();
        }}
      >
        <chakra.li
          onClick={(e) => {
            e.stopPropagation();
          }}
          id="not-overlay"
          overflowY="scroll"
          position="relative"
          maxW={['100%', 500, 500, 600]}
          minW={['100%', 500, 500, 600]}
          rounded={[null, 'lg']}
        >
          <Stack
            as={motion.div}
            tabIndex="0"
            key={id}
            spacing={0}
            position="relative"
            layoutId={`card-container-${id}`}
          >
            <MotionBox
              position="relative"
              layoutId={`card-image-container-${id}`}
            >
              <AspectRatio w="100%" maxHeight={300} ratio={16 / 10}>
                <ImageResponsive
                  alt={title}
                  src={`./images/${id}.jpg`}
                  roundedTop={[null, 'lg']}
                />
              </AspectRatio>
              <HStack
                position="absolute"
                justifyContent="space-between"
                top={0}
                left={0}
                right={0}
                mx={6}
                my={6}
              >
                <Heading
                  as="h3"
                  rounded="md"
                  px={2}
                  py={1}
                  bg="white"
                  size="sm"
                  fontWeight={900}
                  textTransform="uppercase"
                  opacity={imageTag ? 1 : 0}
                >
                  {imageTag}
                </Heading>
                <Center rounded="full" bg="gray.100">
                  <CloseButton ref={buttonRef} onClick={close} />
                </Center>
              </HStack>
            </MotionBox>

            <Stack id="content" bg="white" spacing={4} overflowY="scroll">
              <Stack spacing={4} px={[4, 8]} py={[4, 8]}>
                <Stack
                  as={motion.div}
                  layoutId={`title-container-${id}`}
                  paddingTop={2}
                  spacing={[2, 4]}
                >
                  <Stack spacing={0}>
                    <Heading
                      as="h1"
                      size="lg"
                      m={0}
                      color="gray.800"
                      fontWeight={400}
                    >
                      {title}
                    </Heading>
                    <Text fontSize="sm" color="gray.700">
                      {region?.toLocaleUpperCase()}
                    </Text>
                  </Stack>
                  <Heading
                    as="p"
                    size="sm"
                    m={0}
                    fontWeight={400}
                    color="gray.700"
                  >
                    {description}
                  </Heading>
                  <HStack>
                    {tags.map(([icon, tag]) => (
                      <Tag
                        size="md"
                        key={tag}
                        variant="outline"
                        colorScheme="gray"
                      >
                        <TagLeftIcon boxSize="12px" as={icon} />
                        <TagLabel>{tag}</TagLabel>
                      </Tag>
                    ))}
                  </HStack>
                </Stack>
              </Stack>
              <Stack
                px={[4, 8]}
                paddingTop={[6, 12]}
                paddingBottom={[40, 24]}
                bg={useColorModeValue('gray.100', 'gray.900')}
                spacing={6}
              >
                <Button
                  colorScheme="teal"
                  as={Link}
                  isExternal
                  href={link}
                  onClick={() => {
                    window?.plausible?.('GO_TO_BAR', {
                      props: {
                        link,
                      },
                      callback: () => console.info('Sent GO_TO_BAR event'),
                    });
                  }}
                >
                  <Text as="span" isTruncated>
                    {`Order from ${provider}`.toLocaleUpperCase()}
                  </Text>
                </Button>
                {deliveryNotes && (
                  <>
                    <Button
                      as={Text}
                      rightIcon={
                        deliveryNotesOpen ? <FaCaretUp /> : <FaCaretDown />
                      }
                      fontSize="sm"
                      fontWeight={400}
                      color={deliveryColor}
                      variant="link"
                      onClick={onToggle}
                    >
                      {deliveryNotesOpen ? 'close' : 'delivery notes'}
                    </Button>
                    <SlideFade in={deliveryNotesOpen}>
                      <Text
                        lineHeight={1.3}
                        fontSize="sm"
                        color={deliveryColor}
                        textTransform="capitalize"
                      >
                        {deliveryNotes || ``}
                      </Text>
                    </SlideFade>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
        </chakra.li>
      </VStack>
    </>
  );
}
